import React from "react"

import Layout from "../components/layout"
import { useGlobalState } from "../global/global"

import "../styles/about-us.css"

const AboutUs = props => {
  const [state, dispatch] = useGlobalState()

  const setImageSrc = name => {
    if (state.lightThemeState) {
      return "/images/light/" + name
    }

    if (!state.lightThemeState) {
      return "/images/dark/" + name
    }
  }

  return (
    <Layout location={props.location}>
      <div className="about-us-page">
        <div className="about-us-page__top">
          <div className="about-us-page__top__left">
            <h3 className="about-us-page__top__left__title">About Us</h3>
            <p className="about-us-page__top__left__detail">
              Dataunitor AS is a provider of products and solutions which
              targets demanding intelligence and decision support applications
              where security and integrity of big data processing is crucial. We
              complement our efforts in big data analytics with modeling and
              simulation solutions creating hybrid and connected digital and
              physical environments. Our actionable intelligence approach means
              that the analytics provided by our solutions is prepared for human
              decision making as well as consumption by machine for automation
              purposes. <br />
              <br />
              We are research driven in our approach engaging in deep tech and
              emerging technology projects. Our research and technology
              development is supported and acknowledged by public and private
              research investments and grant organisations. Dataunitor possesses
              a high value Intellectual Property portfolio. This consist of
              patents, trademarks and trade secrets in form of copyrights. These
              innovations were born in the intersection of decades of R&D and
              commercial engagement with the defence industries, vast computer
              science expertise, enabling technologies such as blockchain and
              deep learning and deep academic understanding of the underlying
              research initiatives.
            </p>
          </div>
        </div>
        {/* <div className="about-us-page__line"></div> */}
        {/* <div id="team" className="about-us-page__middle"> */}
        {/* <h1 className='about-us-page__middle__title'>
                        About Us
                </h1>
                    <p className='about-us-page__middle__detail'>
                        Dataunitor AS is provider of products and solutions which targets demanding intelligence and decision support applications where security and integrity of big data processing is crucial. We complement our efforts in big data analytics with modeling & simulation solutions creating hybrid and connected digital and physical environments.
                        We are research driven in our approach engaging in deep tech and emerging technology projects. Our research technology development is supported and acknowledged by public and private research investments and grant organisations.    <br />
                        <br />
                        Dataunitor possesses a high value Intellectual Property portfolio. This consist of patents, trademarks and trade secrets in form of copyrights.
                    </p> */}
        {/* <h1 className="about-us-page__middle__subtitle">Team</h1>
          <div className="about-us-page__middle__team">
            <div className="about-us-page__middle__team__member">
              <img
                className="about-us-page__middle__team__member__image"
                src="/images/profile/Erdal_Cayirci.png"
                alt="profile image"
              />
              <div className="about-us-page__middle__team__member__header"> */}
        {/* <a
                  href="https://www.linkedin.com/in/erdal-c-79004212b/"
                  target="_blank"
                >
                  <img
                    className="about-us-page__middle__team__member__icon"
                    src={setImageSrc("linkedin.svg")}
                    alt="linkedin icon"
                  />
                </a> */}
        {/* <h5 className="about-us-page__middle__team__member__name">
                  Erdal Cayirci
                </h5>
              </div>

              <p className="about-us-page__middle__team__member__authority">
                Founder and Chairman
              </p>
            </div>
            <div className="about-us-page__middle__team__member">
              <img
                className="about-us-page__middle__team__member__image"
                src="/images/profile/KristineHaugan2.png"
                alt="profile image"
              />
              <div className="about-us-page__middle__team__member__header">
                <a
                  href="https://www.linkedin.com/in/kristine-haugan-0653b8213/"
                  target="_blank"
                >
                  <img
                    className="about-us-page__middle__team__member__icon"
                    src={setImageSrc("linkedin.svg")}
                    alt="linkedin icon"
                  />
                </a>
                <h5 className="about-us-page__middle__team__member__name">
                  Kristine Haugan
                </h5>
              </div>

              <p className="about-us-page__middle__team__member__authority">
                Chief Operating Officer (COO) / Point of Contact (POC)
              </p>
              <div className="about-us-page__middle__team__member__contact">
                <div className="about-us-page__middle__team__member__contact_group">
                  <img
                    className="about-us-page__middle__team__member__contact__image"
                    src={setImageSrc("mail.svg")}
                    alt="email icon"
                  />
                  <a
                    href="mailto:kristine@dataunitor.com"
                    className="about-us-page__middle__team__member__contact__detail"
                  >
                    kristine@dataunitor.com
                  </a>
                </div>
              </div>
            </div>
            <div className="about-us-page__middle__team__member">
              <img
                className="about-us-page__middle__team__member__image"
                src="/images/profile/Mehmet_Yagiz_Pehlivan.png"
                alt="profile image"
              />
              <div className="about-us-page__middle__team__member__header"> */}
        {/* <a
                  href="https://www.linkedin.com/in/hmeliskilic/"
                  target="_blank"
                >
                  <img
                    className="about-us-page__middle__team__member__icon"
                    src={setImageSrc("linkedin.svg")}
                    alt="linkedin icon"
                  />
                </a> */}
        {/* <h5 className="about-us-page__middle__team__member__name">
                  M. Yagiz Pehlivan{" "}
                </h5>
              </div>

              <p className="about-us-page__middle__team__member__authority">
                Product Manager - hymots
                <sup>
                  <small>®</small>
                </sup>
                , etecube
                <sup>
                  <small>®</small>
                </sup>
              </p>
            </div>
            <div className="about-us-page__middle__team__member">
              <img
                className="about-us-page__middle__team__member__image"
                src="/images/profile/Mustafa_Karacay.png"
                alt="profile image"
              />
              <div className="about-us-page__middle__team__member__header"> */}
        {/* <a
                  href="https://www.linkedin.com/in/kaansaylam"
                  target="_blank"
                >
                  <img
                    className="about-us-page__middle__team__member__icon"
                    src={setImageSrc("linkedin.svg")}
                    alt="linkedin icon"
                  />
                </a> */}
        {/* <h5 className="about-us-page__middle__team__member__name">
                  Mustafa Karacay{" "}
                </h5>
              </div>

              <p className="about-us-page__middle__team__member__authority">
                Product Manager - nginr
                <sup>
                  <small>®</small>
                </sup>
                , SC3
              </p>
            </div>

            <p className="about-us-page__middle__detail">
              The solutions and service capabilities are empowered by a diverse team of highly skilled engineers.
            </p>
          </div>
        </div> */}
        {/* <div className='about-us-page__line'></div>
                <div className='about-us-page__bottom'>
                    <h1 className='about-us-page__bottom__title'>
                        
                    </h1>
                    <div className='about-us-page__bottom__news'>
                        <div className='about-us-page__bottom__news__news'>
                            <img className='about-us-page__bottom__news__news__image' />
                            <div className='about-us-page__bottom__news__news__content'>
                                <h3 className='about-us-page__bottom__news__news__content__title'>
                                    
                                </h3>
                                <p className='about-us-page__bottom__news__news__content__time'>
                                   
                                </p>
                                <p className='about-us-page__bottom__news__news__content__detail'>
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    </Layout>
  )
}

export default AboutUs
